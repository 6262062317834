import { SoftwarePackage } from '@models/softwarePackge';
import { colors, v2Colors } from '@web-for-marketing/react-ui';

export const nlSoftwarePackages: SoftwarePackage = {
    seo: {
        title: 'Softwarepakketten',
        description: 'Vergelijk functies en kies het gewenste plan.',
    },
    bannerContent: {
        title: 'Softwarepakketten',
        description: 'Vergelijk functies en kies het gewenste plan.',
    },
    softwarePackagesComparisonTable: {
        title: 'Planvergelijkingen',
        subHeaders: ['Productiviteit', 'Optimalisatie wagenpark', 'Naleving', 'Veiligheid', 'Uitbreidbaarheid'],
        showLessFeatures: 'Toon minder functies',
        showMoreFeatures: 'Toon meer functies',
        footNotes: [
            '*Bepaalde functies kunnen vertraging ondervinden met de Base- en Pro-abonnementen als gevolg van beperkingen ten aanzien van gegevens, netwerken of verwerking.',
            '**Sommige functies van het Base-plan zijn mogelijk beperkt vanwege gebrek aan ondersteuning voor motorstatusgegevens.',
            '***Lichte voertuigen, alleen VS en Canada, met gebruik van het ProPlus-plan voor het hele wagenpark. Er zijn andere voorwaarden van toepassing.',
        ],
        buttons: {
            planDetail: {
                text: 'Zie details pakket',
                link: 'https://docs.google.com/document/d/1MNB3FF9rU0AsG0KKw6bMf2yF8DU3FcNhxDial_7eUdo/edit',
            },
            contact: {
                text: 'ContContacteer de verkoopafdeling',
                link: '/nl/contact-opnemen/verkoopvraag/',
            },
        },
        plansAvailability: {
            base: true,
            regulatory: true,
            pro: true,
            proPlus: true,
        },
        baseDetails: [
            {
                index: 0,
                cols: 1,
                title: 'Basis',
                color: v2Colors.core.innovation,
                subTitle: 'Wereldwijd',
                description: 'Het basisplan biedt GPS-locatie, VIN, chauffeurs-ID en basisondersteuning voor IOX.',
            },
            {
                index: 1,
                cols: 1,
                title: 'Regulatory',
                color: colors.core.webEssentials.red,
                subTitle: 'VS en Canada',
                description:
                    'In het Regulatory-plan worden functies toegevoegd aan het Base-plan, voor het bijhouden van diensturen, het aanmaken van IFTA-rapportages en voor temperatuurbewaking. Het plan is erop gericht wagenparken te laten voldoen aan een reeks voorschriften.',
            },
            {
                index: 2,
                cols: 1,
                title: 'Pro',
                color: v2Colors.core.geotab,
                subTitle: 'Wereldwijd',
                description:
                    'Het Pro-abonnement biedt de functionaliteit van het Regulatory-plan en voegt ondersteuning toe voor motor- en snelheidsmetergegevens.',
            },
            {
                index: 3,
                cols: 1,
                title: 'ProPlus',
                color: colors.core.webEssentials.green,
                subTitle: 'Wereldwijd',
                description:
                    'Het ProPlus-abonnement biedt de beste functionaliteit, waaronder Active Tracking, een levenslange garantie en premium services ',
            },
        ],
        baseDataDetails: [
            {
                index: 0,
                title: '',
                iconColor: v2Colors.core.innovation,
                data: ['GPS-locatie', 'VIN', 'Chauffeurs-ID', 'Basisondersteuning voor IOX'],
            },
            {
                index: 1,
                title: 'Alle functionaliteit van het Base-plan plus',
                iconColor: colors.core.webEssentials.red,
                data: [
                    'Diensturen',
                    'IFTA',
                    'Temperatuurbewaking',
                    'Helpt wagenparken te voldoen aan nalevingsvoorschriften',
                ],
            },
            {
                index: 2,
                title: 'Alle functionaliteit van het Base-plan plus',
                iconColor: v2Colors.core.geotab,
                data: ['Motorgegevens', 'Snelheidsmetergegevens'],
            },
            {
                index: 3,
                title: 'Alle Pro-functies plus',
                iconColor: colors.core.webEssentials.green,
                data: ['Actief traceren', 'Levenslange garantie', 'Geavanceerde IOX-ondersteuning', 'EV-ondersteuning'],
            },
        ],
        productivityPlans: [
            {
                title: 'Directe GPS-tracking met gepatenteerde, op curven gebaseerde algoritmen voor optimale registratie',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Zeer flexibele tool voor regels en uitzonderingen - aanpasbaar en bewerkbaar',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Rapportage chauffeursgroepering',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Dichtstbijzijnde voertuig zoeken op adres',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Chauffeurs-ID via NFC',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Stuur berichten en routes naar chauffeurs met compatibele Garmin-PND\'s',
                boldTitlePrefix: '',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        fleetOptimizationPlans: [
            {
                title: 'Herinneringen voor voertuigonderhoud gepland op basis van tijd of afstand',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Herinneringen voor voertuigonderhoud gepland op basis van kilometerstand of motoruren',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Brandstofmanagementplatform - Volgen van tankbeurten, aftappen van brandstof, brandstofdiefstal, integratie van brandstofpassen, etc.',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Motorstatusgegevens voor alle belangrijke motorprotocollen (brandstofverbruik/accuspanning/koelvloeistof/temperatuur)	',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Eenvoudige apparaatinstallatie met zelfkalibrerende versnellingsmeter',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Storingsgegevens registreren en interpreteren (J1939, 1708, OBD, CAN)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Add-In pechhulp Geotab Roadside voor gratis slepen, slotenmaker, accuboost, brandstoflevering, vervanging van lekke banden, en nog veel meer.***',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        compliancePlans: [
            {
                title: 'Detectie van apparaatmanipulatie',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'DVIR-conformiteit via Geotab Drive (Android/iOS)',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Driver Application (Geotab Drive) schaalbaar voor toekomstige behoeften',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'IFTA-registratie van kilometrage',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'DU-naleving via Geotab Drive (Android/iOS) en Garmin',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Privé-modus',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        safetyPlans: [
            {
                title: 'Akoestische waarschuwingen en meldingen snelheidsoverschrijdingen, stationaire loop, en identificatie van chauffeurs',
                boldTitlePrefix: 'Basiscoaching voor chauffeurs -- ',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Akoestische waarschuwingen en meldingen voor ruw remmen, scherp bochten nemen, te hoge acceleratie, gebruik van de veiligheidsgordel, voertuig in achteruit, te hoge toerentallen, etc. plus basiscoaching voor chauffeurs en aanpasbare regels en uitzonderingen aan de serverzijde',
                boldTitlePrefix: 'Geavanceerde coaching voor chauffeurs --',
                base: true,
                baseAsterisk: '****',
                regulatory: true,
                regulatoryAsterisk: '****',
                pro: true,
                proAsterisk: '****',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        expandabilityPlans: [
            {
                title: 'Online software-rapportageplatform (SaaS) dat ondersteuning biedt voor een onbeperkt aantal voertuigen en gebruikers',
                boldTitlePrefix: '',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: '',
                title: 'Ondersteuning voor meerdere kaarttypen',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Uitgebreide rapportagefunctionaliteit – bewerkbaar, aanpasbaar, trends, etc.',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Aanpasbare dashboards',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Flexibel toegangsbeheer voor gebruikers',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'MyGeotab-toegang via Android en iOS',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'SDK en API\'s die open, gratis en gebruiksvriendelijk zijn',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Toegang tot add-ons en oplossingen van partners via Geotab Marketplace',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: 'Basic',
                title: 'Integratie van Basic IOX Add-On (IOX-GOTALK, IOX-AUX, IOX-BUZZ, IOX-NFCREADER, etc.) met randapparatuur en apparaten in het voertuig',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: 'Geavanceerde',
                title: 'integratie in het voertuig van IOX-add-on met randapparatuur en apparaten (IOX-BT, IOX-USB, IOX-CAN, en Add-Ons voor controllers van substantieverspreiders IOX-DJ DICKEY-john®, IOX-CRS Cirus Controls® SpreadSmart Rx®, IOX-COMSPREAD Rexroth® Compu-Spread®, IOX-FAM FORCE AMERICA® 5100ex, etc.)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Active Tracking – een premium trackingoplossing die locatie-informatie met een hogere frequentie en precisie levert',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Gebruikte EV-rijenergie',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV-acculading in % (SOC)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV-laadstatus',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'EV-laadenergie',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Beperkte levenslange apparaatgarantie',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
    },
};
